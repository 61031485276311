<template>
    <header>
        <nav
            class="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-2 lg:px-8"
            aria-label="Global"
        >
            <div class="flex lg:flex-1">
                <NuxtLink to="/" class="-m-1.5 p-1.5">
                    <span class="sr-only">DP</span>
                    <NuxtImg
                        class="w-14 h-14"
                        src="/logos/default.svg"
                        alt="Domain Pool"
                    />
                    <div>Domain Pool</div>
                </NuxtLink>
            </div>

            <div class="hidden lg:flex lg:gap-x-12">
                <NuxtLink
                    v-for="item in navigation"
                    :key="item.name"
                    :href="item.href"
                    class="text-sm font-semibold leading-6 dark:text-slate-100"
                    >{{ item.name }}
                </NuxtLink>
            </div>
            <div class="flex flex-1 items-center justify-end">
                <!-- <StoreCartPopover /> -->
                <!-- Might be causing a memory leak -->
                <NuxtLink to="/cart" title="Go to your cart">
                    <UButton
                        title="Cart"
                        icon="i-heroicons-shopping-bag"
                        size="lg"
                        color="white"
                        variant="ghost"
                    />
                </NuxtLink>
                <div class="hidden sm:flex ml-2 gap-x-6">
                    <NuxtLink to="/" v-if="!isAuthenticated" @click="login">
                        <UButton label="Log in" variant="ghost" />
                    </NuxtLink>
                    <NuxtLink to="/" v-else @click="signout">
                        <UButton label="Signout" variant="ghost" />
                    </NuxtLink>
                    <NuxtLink to="/" v-if="!isAuthenticated" @click="login">
                        <UButton label="Sign up" />
                    </NuxtLink>
                    <NuxtLink to="/dashboard" v-else>
                        <UButton label="Dashboard" />
                    </NuxtLink>
                </div>
            </div>

            <div class="flex lg:hidden">
                <button
                    type="button"
                    class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-slate-700 dark:text-slate-300"
                    @click="mobileMenuOpen = true"
                >
                    <span class="sr-only">Open main menu</span>
                    <UIcon
                        name="i-heroicons-bars-3"
                        class="h-6 w-6"
                        aria-hidden="true"
                    />
                </button>
            </div>
        </nav>

        <div>
            <USlideover v-model="mobileMenuOpen">
                <UCard
                    class="flex flex-col flex-1"
                    :ui="{
                        body: { base: 'flex-1' },
                        ring: '',
                        divide: 'divide-y divide-gray-100 dark:divide-gray-800',
                    }"
                >
                    <div class="flex items-center gap-x-6">
                        <NuxtLink to="/" class="-m-1.5 p-1.5">
                            <span class="sr-only">DP</span>
                            <NuxtImg
                                class="w-14 h-14"
                                src="/logos/default.svg"
                                alt="Domain Pool"
                            />
                            <div>Domain Pool</div>
                        </NuxtLink>

                        <button
                            type="button"
                            class="ml-auto -m-2.5 rounded-md p-2.5 text-slate-700"
                            @click="mobileMenuOpen = false"
                        >
                            <span class="sr-only">Close menu</span>
                            <UIcon
                                name="i-heroicons-x-mark"
                                class="h-6 w-6"
                                aria-hidden="true"
                            />
                        </button>
                    </div>
                    <div class="mt-6 flow-root">
                        <div class="-my-6 divide-y divide-gray-500/10">
                            <div class="space-y-2 py-6">
                                <NuxtLink
                                    v-for="item in navigation"
                                    :key="item.name"
                                    :href="item.href"
                                    class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 dark:text-slate-100 hover:bg-slate-50"
                                    >{{ item.name }}</NuxtLink
                                >
                            </div>
                            <div class="py-6">
                                <div>
                                    <NuxtLink
                                        to="/"
                                        v-if="!isAuthenticated"
                                        @click="login"
                                    >
                                        <UButton label="Sign up" />
                                    </NuxtLink>
                                    <NuxtLink to="/dashboard" v-else>
                                        <UButton label="Dashboard" />
                                    </NuxtLink>
                                </div>
                                <div class="mt-2">
                                    <NuxtLink
                                        to="/"
                                        v-if="!isAuthenticated"
                                        @click="login"
                                    >
                                        <UButton
                                            label="Log in"
                                            variant="ghost"
                                        />
                                    </NuxtLink>
                                    <NuxtLink to="/" v-else @click="signout">
                                        <UButton
                                            label="Signout"
                                            variant="ghost"
                                        />
                                    </NuxtLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </UCard>
            </USlideover>
        </div>
    </header>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Dialog, DialogPanel } from '@headlessui/vue'
import { useAuth0 } from '@auth0/auth0-vue'

const navigation = [
    { name: 'Register New Domains', href: '/search-for-domains' },
    { name: 'Transfer Domains', href: '/search-for-domains' },
    // { name: 'Manage', href: '#' },
    // { name: 'Plans', href: '#' },
]

const auth0 = process.client ? useAuth0() : undefined

function login() {
    if (process.client) {
        auth0?.loginWithRedirect()
    }
}

function signout() {
    if (process.client) {
        auth0?.logout({ logoutParams: { returnTo: window.location.origin } })
    }
}

const isAuthenticated = computed(() => {
    return auth0?.isAuthenticated.value
})

const mobileMenuOpen = ref(false)
</script>
